import React, { useState } from "react";
import emailjs from "emailjs-com";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextareaAutosize,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import "../styles/App.css";
import Recaptcha from "react-recaptcha";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: "10px",
      width: "100%",
      maxWdith: "100%",
    },
  },
  textArea: {
    fontSize: "17px",
    padding: "5px",
  },
}));

function Schedule() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [nameHelper, setNameHelper] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneHelper, setPhoneHelper] = useState("");

  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");

  const [select, setSelect] = useState("");

  const [comment, setComment] = useState("");
  const [commentHelper, setCommentHelper] = useState("");

  const [city, setCity] = useState("");
  const [date, setDate] = useState("");

  const [verify, setRecaptcha] = useState(false);

  const recaptchaLoaded = () => {
    console.log("recaptcha has successfully loaded");
  };

  const verifyCallback = (response) => {
    if (response) {
      setRecaptcha(true);
    }
  };

  const validate = () => {
    setNameHelper("");
    setPhoneHelper("");
    setEmailHelper("");
    setCommentHelper("");

    if (name === "") {
      setNameHelper("Your name is required");
      return false;
    }

    if (email === "" && phone === "") {
      setEmailHelper("An email or phone number is required");
      setPhoneHelper("An email or phone number is required");
      return false;
    }

    if (comment === "") {
      setCommentHelper("A message is required");
      return false;
    }

    if (!verify) {
      setCommentHelper("Please fill out reCAPTCHA");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      emailjs
        .sendForm(
          "service_5qskp8s",
          "template_3ximswu",
          e.target,
          "user_mtEFjUvFGu8PXq01C1OnK"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert(
              "You request has been submitted! Thank you so much for your interest in Pointblank Moving! We will reach out to you soon with further details!"
            );
          },
          (error) => {
            console.log(error.text);
            alert(
              "An error occurred while submitting your request. Please try again. We look forward to hearing from you!"
            );
          }
        );
      setName("");
      setPhone("");
      setEmail("");
      setComment("");
      setCity("");
      setDate("");
      setSelect("");
      setRecaptcha(false);

      setNameHelper("");
      setPhoneHelper("");
      setEmailHelper("");
      setCommentHelper("");
    }
  };

  return (
    <div className="form-img">
      <NavBar
        title="Free Quote | Point Blank Moving"
        description="Kansas City Moving company Quote for services. Quote Point Blank Moving the best moving company in Kansas City Missouri. Fill out our form to get a quote for your moving service. We will be in contact soon. Quotes for Kansas City moving."
      />
      <div className="align-form">
        <div className="form">
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            style={{
              padding: "0px 20px 0px 20px",
              display: "flex",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <h1>Get a quote</h1>
            <TextField
              type="text"
              placeholder="Name*"
              name="name"
              helperText={nameHelper}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <TextField
              type="text"
              placeholder="Phone*"
              name="phone"
              helperText={phoneHelper}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
            <TextField
              type="text"
              placeholder="Email*"
              name="email"
              helperText={emailHelper}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <FormControl>
              <InputLabel>How did you hear about us?</InputLabel>
              <Select
                name="select"
                value={select}
                onChange={(e) => setSelect(e.target.value)}
              >
                <MenuItem value={"Web Search"}>Internet</MenuItem>
                <MenuItem value={"Google Maps"}>Google Maps</MenuItem>
                <MenuItem value={"Thumbtack"}>Thumbtack</MenuItem>
                <MenuItem value={"Referral"}>Referral</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              type="text"
              placeholder="Desired date"
              name="date"
              onChange={(e) => setDate(e.target.value)}
              value={date}
            />
            <TextField
              type="text"
              placeholder="City / State"
              name="city"
              onChange={(e) => setCity(e.target.value)}
              value={city}
            />
            <TextareaAutosize
              className={classes.textArea}
              type="text"
              placeholder="What can we do for you?"
              name="comment"
              rowsMin={4}
              defaultValue={commentHelper}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />

            <Button type="submit" className="schedule-btn">
              Submit
            </Button>
            <div style={{ alignSelf: "center", maxWidth: "90%" }}>
              <Recaptcha
                sitekey="6LfmmqAaAAAAAEeKOHZcj21UF6DFQ-9nWsQtJh36"
                render="explicit"
                onloadCallback={recaptchaLoaded}
                verifyCallback={verifyCallback}
              />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Schedule;
